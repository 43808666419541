import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { SectionHeader, Section, Gallery } from '../styles/styles'
import ImageGallery from '../components/gallery'

const ProcessContainer = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
const ProcessDescription = styled.div`
  flex: 6;
  margin-right: 20px;
`
const Process = styled.div`
  flex: 12;
`
const StaffContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  margin-top: 15px;
`
const StaffCard = styled.div`
  background-color: rgba(27, 28, 29, 0.95);
  box-shadow: 0px 0px 2px #00000080;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  border-radius: 5px;
  @media (max-width: 480px) {
    padding: 20px;
  }
`
const StaffName = styled.h4`
  font-family: 'Roboto Condensed';
  margin-bottom: 0;
`

const StaffTitle = styled.div`
  font-family: 'Roboto Condensed';
  font-size: 1rem;
  color: #b4b4b4;
`

const Brewery = ({ data }) => {
  const {
    backgroundImage,
    staff,
    barrelProgramTitle,
    barrelProgramImages,
    barrelProgramDescription,
    processTitle,
    processDescription,
    processImage
  } = data.contentfulBreweryPage

  console.log(staff)

  return (
    <Layout bgImage={backgroundImage}>
      <SEO title="Brewery" />
      <h1>Brewery</h1>
      <SectionHeader>Behind the Scenes</SectionHeader>
      <Section>
        <ProcessContainer>
          <ProcessDescription>
            <h4>{processTitle}</h4>
            <p dangerouslySetInnerHTML={{ __html: processDescription }}></p>
          </ProcessDescription>
          <Process>
            <Img
              key={processImage.fluid.src}
              alt="process"
              fluid={processImage.fluid}
            />
          </Process>
        </ProcessContainer>
      </Section>
      <SectionHeader>Our Barrel Program</SectionHeader>
      <Section>
        <ProcessContainer>
          <ProcessDescription>
            <h4>{barrelProgramTitle}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: barrelProgramDescription.barrelProgramDescription.replace(
                  /\n/g,
                  '<br />'
                )
              }}
            ></p>
          </ProcessDescription>
          <Process>
            <ImageGallery images={barrelProgramImages} />
          </Process>
        </ProcessContainer>
      </Section>
      <SectionHeader>Meet the Family</SectionHeader>
      <StaffContainer>
        {staff.map(({ bio, picture, name, jobTitle }) =>
          name ? (
            <StaffCard key={name}>
              <Img
                fluid={picture.fluid}
                style={{
                  width: '100%',
                  maxWidth: 150,
                  borderRadius: '50%',
                  marginBottom: 20
                }}
              />
              <StaffName>{name}</StaffName>
              <StaffTitle>{jobTitle}</StaffTitle>
              <p style={{ marginTop: 15 }}>{bio.bio}</p>
            </StaffCard>
          ) : (
            <div />
          )
        )}
      </StaffContainer>
    </Layout>
  )
}

export default Brewery

export const query = graphql`
  query BreweryQuery {
    contentfulBreweryPage {
      staff {
        bio {
          bio
        }
        picture {
          fluid(maxWidth: 1000) {
            src
            srcSet
            sizes
            srcSetWebp
            srcWebp
            tracedSVG
            aspectRatio
          }
        }
        name
        jobTitle
      }
      processTitle
      processImage {
        fluid(maxWidth: 1000) {
          src
          srcSet
          sizes
          srcSetWebp
          srcWebp
          tracedSVG
          aspectRatio
        }
      }
      backgroundImage {
        fluid(maxWidth: 1024) {
          src
          srcSet
          sizes
          srcSetWebp
          srcWebp
          base64
          aspectRatio
        }
      }
      barrelProgramDescription {
        barrelProgramDescription
      }
      barrelProgramImages {
        fixed(width: 200) {
          width
          height
          src
          srcSet
        }
        fluid {
          src
          srcSet
          sizes
          srcSetWebp
          srcWebp
          base64
          aspectRatio
        }
      }
      barrelProgramTitle
      pageTitle
      processDescription
    }
  }
`
